<template>
  <div>
    <a-card :title="$t('容器收货')">
      <a-button slot="extra" type="primary" ghost @click="$router.go(-1)"> <a-icon type="left" />{{ $t("返回") }}</a-button>
      <a-spin :spinning="createLoading">
        <div style="margin-top: 16px">
          <a-table
            rowKey="id"
            size="small"
            :columns="curColumns"
            :data-source="receiptRecordMaterialItems"
            :loading="materialLoading"
            :pagination="false"
            :scroll="{ x: 1800 }"
          >
            <div slot="receipt_quantity" slot-scope="value, item">
              <a-input-number
                v-model="item.receipt_quantity"
                size="small"
                :max="item.remain_quantity"
                @change="changeQuantity(item)"
              />
            </div>
            <div slot="status" slot-scope="value, item">
              <a-select v-model="item.status" size="small" style="width: 100px">
                <a-select-option key="qualified" value="qualified">{{ $t("良品") }}</a-select-option>
                <a-select-option key="unqualified" value="unqualified">{{ $t("不良品") }}</a-select-option>
              </a-select>
            </div>
            <div slot="batch_number" slot-scope="value, item, index">
              <a-row gutter="4">
                <a-col :span="20">
                  <a-input v-model="item.batch_number" :disabled="!item.enable_batch_control" size="small"></a-input>
                </a-col>
                <a-col :span="4">
                  <a-button type="primary" icon="plus" size="small" @click="addBatch(item, index)"></a-button>
                </a-col>
              </a-row>
            </div>
            <div slot="pallet_number" slot-scope="value, item, index">
              <a-input v-model="item.pallet_number" size="small"></a-input>
            </div>

            <div slot="charge_value" slot-scope="value, item">
              <a-input-number v-model="item.charge_value" size="small" />
            </div>
            <div slot="production_date" slot-scope="value, item">
              <div v-if="item.enable_shelf_life">
                <a-date-picker v-model="item.production_date" size="small" valueFormat="YYYY-MM-DD" />
              </div>
            </div>
            <div slot="action" slot-scope="value, item, index">
              <a-button-group size="small">
                <a-button type="danger" @click="removeMaterial(index)">{{ $t("删除") }}</a-button>
              </a-button-group>
            </div>
          </a-table>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="createLoading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import { receiptMaterialOption } from "@/api/option";
import { receiptRecordCreate } from "@/api/stockIn";
import { columns } from "./columns";
import { rules } from "./rules";
import moment from "moment";
import NP from "number-precision";

export default {
  data() {
    return {
      rules,
      moment,
      form: {},
      receiptOrder: undefined,
      receiptRecordMaterialItems: [],
      createLoading: false,
      materialLoading: false,
    };
  },
  computed: {
    enableCharge() {
      return this.$store.state.user.enableCharge;
    },
    enableQualityInspection() {
      return this.$store.state.user.enableQualityInspection;
    },
    curColumns() {
      const items = [];
      for (const item of columns) {
        if (!this.enableCharge && item.key === "charge_value") {
          continue;
        }

        if (!this.enableCharge && item.key === "charge_unit") {
          continue;
        }

        if (!this.enableQualityInspection && item.key === "quality_inspection_status") {
          continue;
        }

        items.push(item);
      }
      return items;
    },
  },
  methods: {
    initData() {
      this.resetForm();

      this.receiptOrder = this.$route.query.receipt_order;
      this.materialLoading = true;

      receiptMaterialOption({ receipt_order: this.receiptOrder, is_completed: false, page_size: 999999 })
        .then((data) => {
          const receiptRecordMaterialItems = [];
          for (const item of data.results) {
            if (item.enable_pallet && item.pallet_capacity && item.pallet_capacity > 0) {
              let totalQuantity = item.remain_quantity;
              let palletCount = Math.ceil(totalQuantity / item.pallet_capacity);
              for (let index = 0; index < palletCount; index++) {
                let receiptQuantity = totalQuantity > item.pallet_capacity ? item.pallet_capacity : totalQuantity;
                totalQuantity = NP.minus(totalQuantity, receiptQuantity);
                receiptRecordMaterialItems.push({
                  ...item,
                  id: item.id,
                  receipt_material: item.id,
                  material_name: item.material_name,
                  material_number: item.material_number,
                  unit: item.unit,
                  material_spec: item.material_spec,
                  weight: item.weight,
                  enable_shelf_life: item.enable_shelf_life,
                  shelf_life_days: item.shelf_life_days,
                  remain_quantity: item.remain_quantity,
                  receipt_quantity: receiptQuantity,
                  charge_value: this.getChargeValue(item, item.remain_quantity),
                  total_quantity: item.total_quantity,
                  production_date: undefined,
                  batch_number: item.batch_number,
                  status: "qualified",
                  enable_batch_control: item.enable_batch_control,
                  enable_pallet: item.enable_pallet,
                  pallet_capacity: item.pallet_capacity,
                  pallet_number: undefined,
                });
              }
            }
          }
          this.receiptRecordMaterialItems = receiptRecordMaterialItems;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
    removeMaterial(index) {
      let receiptRecordMaterialItems = [...this.receiptRecordMaterialItems];
      receiptRecordMaterialItems.splice(index, 1);
      this.receiptRecordMaterialItems = [...receiptRecordMaterialItems];
    },
    create() {
      if (this.receiptRecordMaterialItems.length == 0) {
        this.$message.warn(this.$t("未添加产品"));
        return;
      }

      if (!this.validateMaterial()) {
        return;
      }

      for (const item of this.receiptRecordMaterialItems) {
        if (!item.pallet_number) {
          this.$message.warn(this.$t("未输入容器编号"));
          return;
        }
      }

      let formData = {
        ...this.form,
        receipt_order: this.receiptOrder,
        receipt_record_material_items: this.receiptRecordMaterialItems,
      };

      this.createLoading = true;
      receiptRecordCreate(formData)
        .then((data) => {
          this.$message.success(this.$t("创建成功"));
          this.resetForm();
          this.$router.go(-1);

          // this.$router.push({ path: "/stock_in/receipt_record_detail", query: { id: data.id } });
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    resetForm() {
      this.form = {};
      this.receiptRecordMaterialItems = [];
    },
    validateMaterial() {
      for (let item of this.receiptRecordMaterialItems) {
        if (item.receipt_quantity > item.remain_quantity) {
          this.$message.warn(`${this.$t("产品")}[${item.material_name}] ${this.$t("收货数量不能大于剩余数量")}`);
          return false;
        }

        if (!item.batch_number || this.batch_number == "") {
          this.$message.warn(`${this.$t("产品")}[${item.material_name}] ${this.$t("请输入批次号")}`);
          return false;
        }

        if (item.enable_shelf_life && !item.production_date) {
          this.$message.warn(`${this.$t("产品")}[${item.material_name}] ${this.$t("请选择生产日期")}`);
          return false;
        }
      }

      return true;
    },
    addBatch(item, index) {
      let new_item = { ...item };
      new_item["batch_number"] = item.enable_batch_control ? `B${moment().format("YYYYMMDDHHmm")}` : "N/A";
      this.receiptRecordMaterialItems.splice(index, 0, new_item);
    },
    getChargeValue(item, quantity) {
      if (item.charge_unit === "quantity") {
        return quantity || 0;
      } else if (item.charge_unit === "ton") {
        return NP.times(quantity, item.weight || 0, 0.001);
      } else if (item.charge_unit === "cube") {
        return NP.times(quantity, item.volume || 0);
      }
      return 0;
    },
    changeQuantity(item) {
      item.charge_value = this.getChargeValue(item, item.receipt_quantity);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
